import jwt_decode from 'jwt-decode';

const TOKEN_LIFETIME_DAYS = 14;

export const hasTokenExpired = (token) => {
  if (token) {
    const tokenCreationTime = localStorage.getItem("tokenCreationTime");
    if (tokenCreationTime) {
        const currentTime = new Date().getTime();
        const expirationTime = parseInt(tokenCreationTime) + (TOKEN_LIFETIME_DAYS * 24 * 60 * 60 * 1000);
        return currentTime > expirationTime;
    }
    return true; 
  }
  return true; 
};

export function getAccessToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

export function setTokens({ token, refreshToken, accountType}){
  localStorage.setItem('token', token);
  localStorage.setItem('userRole', accountType);
  localStorage.setItem('refreshToken', refreshToken);

  const creationTime = new Date().getTime();
  localStorage.setItem('tokenCreationTime', creationTime.toString());
 }

 export function setRefreshTokens({ token, refreshToken}){
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);

  const creationTime = new Date().getTime();
  localStorage.setItem('tokenCreationTime', creationTime.toString());
 }